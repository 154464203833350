export const FIND_ALL_CONSTRAINTS = (gql: any) => gql`
    query {
        findAllConstraints {
            code
            name
            min
            max
            minLength
            maxLength
            uuid
            url
            arn
            pattern
            equation
            positive
            negative
            required
            values
            int
            string
            email
            phone
        }
    }
`;
