const importers = {
    screen: [
        ({ path }: any) => import(`./screens/${path}Screen`),
        [
            'main_layout',
            'form_layout',
            'template_layout',
            'fill_form_layout',
            'fill_form_version_layout',
            'public_layout',
            'form_version_layout',
            'form_version_run_layout',
            'form_version_run_solution_layout',
            'catalog',
            'template',
            'new_template',
            'form',
            'form_input',
            'form_version_input',
            'form_output',
            'form_version_output',
            'form_summary',
            'form_documents',
            'form_notes',
            'form_settings',
            'form_versions',
            'run_form',
            'form_version_run',
            'form_version_run_solution',
            'user_home',
            'user_forms',
            'user_all_forms',
            'user_archived_forms',
            'user_profile',
            'user_profile_edit',
            'form_display_layout',
            'form_version_display_layout',
            'form_version',
            'form_version_summary',
            'form_duplicate',
            'register'
        ],
    ],
    content: [
        ({ path }: any) => import(`./contents/${path}Content`),
        [
            'empty',
            'section',
            'form_results',
            'solution_attachments',
            'solution_messages',
            'solution_violations',
            'solution_links',
            'solution_tags',
            'solution_summary',
            'solution_title',
        ],
    ],
    drawer: [({ path }: any) => import(`./drawers/${path}Drawer`), ['catalog_item', 'account', 'myforms', 'catalog']],
    form_field: [({ path }: any) => import(`./fields/${path}Field`), ['matrix', 'computed', 'richValue', 'numberWithUnit', 'listing', 'form']],
};

export default importers;
