export const GET_CURRENT_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;
export const UPDATE_CURRENT_USER = (gql: any) => gql`
    mutation ($data: UpdateCurrentUserInput!) {
        updateCurrentUser(data: $data) {
            id
            status
            firstName
            lastName
            phone
        }
    }
`;