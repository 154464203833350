export const FIND_ALL_LISTINGS = (gql: any) => gql`
    query {
        findAllListings {
            code
            name
            items {
                code
                name
            }
        }
    }
`;
