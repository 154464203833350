export const FIND_ALL_FIELDS = (gql: any) => gql`
    query {
        findAllFields {
            code
            name
            type
            constraints
        }
    }
`;
