import { useCallback} from 'react';
import { useMutationApi, useUserContext } from '@genstackio/react-contexts';
import { RegisterForm } from '../forms/RegisterForm';
import useLocales from '@genstackio/react-contexts/lib/hooks/useLocales';

export function RegisterAction(props: RegisterActionProps) {
    const {setTokens} = useUserContext()
    const [register, {error, loading}] = useMutationApi('REGISTER');
    const { locales } = useLocales();

    const onRegister = useCallback((data: any) => {
        register({variables: {data}}).then((data: any) => {
            const tokens = {token: data.data.register.accessToken, refreshToken: data.data.register.refreshToken}
            setTokens(tokens);
        }).catch(
            () => {
                // silent error, already displayed.
            }
        );
    }, [register, setTokens]);

    return (
        <RegisterForm onSubmit={onRegister} errors={error ? {"": error} : {}} loading={loading} locales={locales.map((x) => ({ value: x.id, language: x.label }))} {...props} />
    );
}

export interface RegisterActionProps {
}

// noinspection JSUnusedGlobalSymbols
export default RegisterAction;
