import LocaleChange from '@genstackio/react-admin-ui/lib/atoms/LocaleChange';
import UsernameField from '@genstackio/react-admin-ui/lib/atoms/fields/UsernameField';
import PasswordField from '@genstackio/react-admin-ui/lib/atoms/fields/PasswordField';
import Div from '@genstackio/react-admin-ui/lib/atoms/Div';
import { BaseFormProps } from '@genstackio/react-admin-ui/lib/molecules/forms/BaseForm';
import useForm from '@genstackio/react-admin-ui/lib/hooks/useForm';
import { WithLocales } from '@genstackio/react-admin-ui/lib/withs';
import ErrorPanel from '../../atoms/ErrorPanel';

const defaultLocales: any[] = [];

export function LoginForm({ children, className, locales = defaultLocales, errors, ...props }: LoginFormProps) {
    const { Form, SubmitButton, field } = useForm(props, 'login');

    return (
        <Form customChildren={children} className={className}>
            {errors?.[''] && <ErrorPanel error={errors?.['']} className='mb-4'/>}
            <UsernameField {...field} autoFocus required />
            <Div mb={'lg'}>
                <Div mb={'sm'} className={'text-sm'}>
                    <Div mb={'xmd'} py={'sm'} className={'leading-tight focus:outline-none focus:shadow-outline'}>
                        <PasswordField {...field} required />
                    </Div>
                    <SubmitButton />
                </Div>
            </Div>
            <LocaleChange locales={locales} />
        </Form>
    );
}

export interface LoginFormProps extends BaseFormProps, WithLocales {
}

// noinspection JSUnusedGlobalSymbols
export default LoginForm;
