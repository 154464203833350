import PasswordField from '@genstackio/react-admin-ui/lib/atoms/fields/PasswordField';
import { BaseFormProps } from '@genstackio/react-admin-ui/lib/molecules/forms/BaseForm';
import useForm from '@genstackio/react-admin-ui/lib/hooks/useForm';
import { WithLocales } from '@genstackio/react-admin-ui/lib/withs';
import { useTranslation } from "react-i18next";
import EmailField from "@genstackio/react-admin-ui/lib/atoms/fields/EmailField"
import LastNameField from "@genstackio/react-admin-ui/lib/atoms/fields/LastNameField"
import FirstNameField from "@genstackio/react-admin-ui/lib/atoms/fields/FirstNameField"
import ErrorPanel from '../../atoms/ErrorPanel';
import LocaleChange from '@genstackio/react-admin-ui/lib/atoms/LocaleChange';

const defaultLocales: any[] = [];

export function RegisterForm({ children, className, locales = defaultLocales, errors, loading, ...props }: RegisterFormProps) {
    const { Form, SubmitButton, field } = useForm(props, 'register');
    const { t } = useTranslation();

    return (
        <Form customChildren={children} className={className}>
            <div className={'flex flex-col w-full gap-4'}>
                {errors?.[''] && <ErrorPanel error={errors?.['']} className='mb-4'/>}
                <EmailField {...field} autoFocus required disabled={loading} kind='email'/>
                <LastNameField {...field} required disabled={loading} />
                <FirstNameField {...field} required disabled={loading} />
                <PasswordField {...field} required disabled={loading} />
                <SubmitButton label={t('screens_register_comfirm')} endIcon={'fa-user'} loading={loading}/>
                <LocaleChange locales={locales} />
            </div>
        </Form>
    );
}

export interface RegisterFormProps extends BaseFormProps, WithLocales {
    loading?: any;
}

// noinspection JSUnusedGlobalSymbols
export default RegisterForm;
