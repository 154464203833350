export const CREATE_NOTE = (gql: any) => gql`
    mutation ($id: String!, $data: CreateNoteInput!) {
        createNote(id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_NOTE = (gql: any) => gql`
    mutation ($id: String!, $data: UpdateNoteInput!) {
        updateNote(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_NOTE = (gql: any) => gql`
    mutation($id: ID!) {
        deleteNote(id: $id) {
            id
        }
    }
`;