import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faMoon } from '@fortawesome/free-regular-svg-icons/faMoon';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons/faCommentAlt';
import { faCommentAlt as faCommentAltSolid } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons/faBell';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons/faCartArrowDown';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faDice } from '@fortawesome/free-solid-svg-icons/faDice';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons/faMoneyBillWave';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faUserTag } from '@fortawesome/free-solid-svg-icons/faUserTag';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons/faFileAudio';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faForward } from '@fortawesome/free-solid-svg-icons/faForward';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons/faFloppyDisk';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons/faBoxArchive';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons/faFileArrowDown';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel';
import { faFileWord } from '@fortawesome/free-solid-svg-icons/faFileWord';
import { faFileVideo } from '@fortawesome/free-solid-svg-icons/faFileVideo';
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons/faFilePowerpoint';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';

const icons = {
    fa: [
        faPlay,
        faUser,
        faCheckSquare,
        faSquare,
        faMoon,
        faLightbulb,
        faStar,
        faStarSolid,
        faExpand,
        faCommentAlt,
        faCommentAltSolid,
        faBell,
        faBellSolid,
        faShoppingCart,
        faCartArrowDown,
        faSearch,
        faUsers,
        faUserTag,
        faUserTie,
        faBuilding,
        faBalanceScale,
        faDice,
        faMoneyBillWave,
        faInfo,
        faTrashAlt,
        faEdit,
        faEye,
        faPlus,
        faTicketAlt,
        faExternalLinkAlt,
        faImage,
        faVideo,
        faFileAudio,
        faForward,
        faFloppyDisk,
        faFile,
        faSpinner,
        faCopy,
        faLock,
        faBoxArchive,
        faXmark,
        faFileArrowDown,
        faFilePdf,
        faFileCsv,
        faFileCode,
        faFileExcel,
        faFileWord,
        faFileVideo,
        faFilePowerpoint,
        faTag
    ],
};

export default icons;
