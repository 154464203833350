export const FIND_TAGS = (gql: any) => gql`
    query {
        findTags {
            cursor
            count
            items {
                id
                code
                name
            }
        }
    }
`;
