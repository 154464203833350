export const FIND_ALL_UNITSYSTEMS = (gql: any) => gql`
    query {
        findAllUnitsystems {
            code
            name
            units {
                code
                name
                convert
            }
        }
    }
`;
