export const FIND_FTEMPLATES = (gql: any) => gql`
    query {
        findFtemplates {
            cursor
            items {
                id
                name
                title
                description
                status
                tags
            }
        }
    }
`;
export const GET_FTEMPLATE = (gql: any) => gql`
    query ($id: ID!) {
        getFtemplate(id: $id) {
            id
            name
            title
            description
            longDescription
            contactEmail
            contactPhone
            contactLastName
            contactFirstName
            contactCalendarUrl
            contactJobTitle
            tags
            thumbnailImage {
                url
            }
            fimages {
                items {
                    mainImage {
                        url
                    }
                    publicToken
                }
            }
            configuration {
                documents {
                    name
                    url
                }
                faqs {
                    question
                    answer
                }
            }
            definition
        }
    }
`;
export const GET_FTEMPLATE_BY_CODE = (gql: any) => gql`
    query ($code: String!) {
        getFtemplateByCode(code: $code) {
            id
            name
            title
            description
            longDescription
            contactEmail
            contactPhone
            contactLastName
            contactFirstName
            contactCalendarUrl
            contactJobTitle
            thumbnailImage {
                url
            }
            fimages {
                items {
                    mainImage {
                        url
                    }
                    publicToken
                }
            }
            configuration {
                documents {
                    name
                    url
                }
                faqs {
                    question
                    answer
                }
            }
            definition
        }
    }
`;