import { red } from '@material-ui/core/colors';
import buildUploader from '@genstackio/react-admin-core/lib/utils/buildUploader';
import loginSvg from './assets/svg/logo.svg';
import loginFullSvg from './assets/svg/logo.svg';
import loginBackgroundJpg from './assets/jpg/wallpaper.jpeg';
import buildExpressionContextValueForMozjexl from '@genstackio/react-admin-ui/lib/utils/buildExpressionContextValueForMozjexl'
import LoginForm from "./molecules/forms/LoginForm";
import LoginTemplate from "./templates/LoginTemplate";

export const app = {
    prefix: 'computo_app',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    locales: ['fr-FR', 'en-US', 'de-DE', 'es-ES', 'it-IT'],
};
export const apiOptions = {
    timeout: 15000,
    publicOperations: {},
};
export const logos = {
    logo: { url: loginSvg, alt: 'Computo' },
    logoFull: { url: loginFullSvg, alt: 'Computo' },
};
export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT);
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: '#FBBD2A',
            },
            secondary: {
                main: '#2663E9',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: 'theme-color2',
};
export { default as routes } from './routes';
export { default as fields } from './fields';
export * as queries from './queries';
export { default as icons } from './icons';
export { default as translations } from './translations';
export { default as importers } from './importers';
export const login = {
    templateComponent: LoginTemplate,
    actionProps: {
        component: LoginForm,
    }
};
export const register = {
    templateProps: {
        blockClassName: 'md:max-w-xl',
        image: { url: loginBackgroundJpg, alt: 'login' },
    },
};

export const expression = buildExpressionContextValueForMozjexl()