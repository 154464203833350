export const CREATE_FORM = (gql: any) => gql`
    mutation ($data: CreateFormInput!) {
        createForm(data: $data) {
            id
            name
            status
        }
    }
`;
export const DUPLICATE_FORM = (gql: any) => gql`
    mutation ($id: String!, $data: DuplicateFormInput!) {
        duplicateForm(id: $id, data: $data) {
            id
            name
            status
        }
    }
`;
export const GET_FORM = (gql: any) => gql`
    query ($id: ID!) {
        getForm(id: $id) {
            id
            ftemplate
            ftemplateName
            ftemplateCode
            data
            name
            reference
            status
            definition
            publicPageUrl
            lastVersion
            lastRun
            versions {
                items {
                    id
                    createdAt
                    updatedAt
                }
            }
            ffiles {
                items {
                    id
                    mainFile {
                        url
                        downloadUrl
                        name
                        available
                        viewUrl
                        contentType
                    }
                    mainImage {
                        url
                        name
                        available
                        realContentType
                    }
                }
            }
            notes {
                items {
                    id
                    title
                    text
                    user
                    userEmail
                    userFirstName
                    userLastName
                    createdAt
                }
            }
        }
    }
`;
export const RUN_FORM = (gql: any) => gql`
    mutation ($id: ID!) {
        runForm(id: $id) {
            id
            version
            form
            status
        }
    }
`;
export const FIND_FORMS = (gql: any) => gql`
    query findForms($offset: String, $limit: Int, $sort: String){
        findForms(offset: $offset, limit: $limit, sort: $sort) {
            count
            cursor
            items {
                definition
                ftemplate
                ftemplateName
                ftemplateCode
                id
                name
                reference
                status
                publicPageUrl
                publicPdfUrl
                updatedAt
            }
        }
    }
`;
export const FIND_SUMMARIZED_FORMS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String){
        findSummarizedForms(offset: $offset, limit: $limit, sort: $sort) {
            count
            cursor
            items {
                ftemplate
                ftemplateName
                ftemplateCode
                id
                name
                reference
                status
                publicPageUrl
                publicPdfUrl
                updatedAt
            }
        }
    }
`;
export const DELETE_FORM = (gql: any) => gql`
    mutation($id: ID!) {
        deleteForm(id: $id) {
            id
        }
    }
`;
export const TOGGLE_ARCHIVE_FORM = (gql: any) => gql`
    mutation ($id: String!) {
        toggleArchiveForm(id: $id) {
            id
        }
    }
`;