export const CREATE_FFILE = (gql: any) => gql`
    mutation ($id: String!, $data: CreateFfileInput!) {
        createFfile(id: $id, data: $data) {
            id
        }
    }
`;
export const DELETE_FFILE = (gql: any) => gql`
    mutation($id: ID!, $formId: ID!) {
        deleteFfile(id: $id, formId: $formId) {
            id
        }
    }
`;