const routes = [
    { path: '/login', exact: true, secured: false, screen: 'login' },
    { path: '/register', exact: true, secured: false, screen: 'register' },
    {
        path: '/public-templates',
        secured: false,
        screen: 'main_layout',
        exact: false,
        routes: [
            {path: '/:id', exact: true, secured: false, screen: 'template'},
        ],
    },
    { path: '/catalog/tags/:tags?', secured: false, exact: true, screen: 'main_layout', routes: [
        { path: '/', secured: false, exact: true, screen: 'public_layout', routes: [
            { path: '/', secured: false, exact: true, screen: 'catalog'},
        ]},
    ]},
    { path: '/', secured: false, exact: true, screen: 'main_layout', routes: [
        { path: '/', secured: false, exact: true, screen: 'public_layout', routes: [
            { path: '/', secured: false, exact: true, screen: 'catalog'},
            { path: '/register', secured: false, exact: true, screen: 'register' }
        ]},
    ]},
    {
        path: '/',
        secured: true,
        screen: 'main_layout',
        exact: false,
        routes: [
            { path: '/my/profile/edit', exact: true, screen: 'user_profile_edit' },
            { path: '/my/profile', exact: true, screen: 'user_profile' },
            { path: '/my/forms/:tab?', exact: false, screen: 'user_forms', routes: [
                { path: '/archived', exact: true, screen: 'user_archived_forms'},
                { path: '/', exact: true, screen: 'user_all_forms'},
            ] },
            { path: '/my', screen: 'user_home' },
            {
                path: '/templates/:id',
                screen: 'template_layout',
                exact: false,
                routes: [
                    {path: '/new', exact: true, screen: 'new_template'},
                ],
            },
            {
                path: '/forms/:id',
                screen: 'form_layout',
                exact: false,
                routes: [
                    { path: '/run', exact: true, screen: 'run_form' },
                    {
                        path: '/versions/:versionId',
                        exact: false,
                        screen: 'form_version_layout',
                        routes: [
                            {
                                path: '/runs/:runId',
                                exact: false,
                                screen: 'form_version_run_layout',
                                routes: [
                                    { path: '/', exact: true, screen: 'form_version_run' },
                                    {
                                        path: '/solutions/:solutionId',
                                        exact: true,
                                        screen: 'form_version_run_solution_layout',
                                        routes: [
                                            {
                                                path: '/',
                                                exact: true,
                                                screen: 'form_version_run_solution',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: '/:tab?',
                                exact: false,
                                screen: 'fill_form_version_layout',
                                routes: [
                                    { path: '/', exact: false, screen: 'form_version_display_layout', routes: [
                                            { path: '/output', exact: false, screen: 'form_version_output', routes: [
                                                {
                                                    path: '/runs/:runId',
                                                    exact: false,
                                                    screen: 'form_version_run_layout',
                                                    routes: [
                                                        { path: '/', exact: true, screen: 'form_version_run' },
                                                        {
                                                            path: '/solutions/:solutionId',
                                                            exact: true,
                                                            screen: 'form_version_run_solution_layout',
                                                            routes: [
                                                                {
                                                                    path: '/',
                                                                    exact: true,
                                                                    screen: 'form_version_run_solution',
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ]},
                                            { path: '/input', exact: true, screen: 'form_version_input' },
                                            { path: '/', exact: true, screen: 'form_version_summary' }
                                        ] }
                                ],
                            },
                        ],
                    },
                    { path: '/duplicate', exact: true, screen: 'form_duplicate' },
                    {
                        path: '/:tab?',
                        exact: false,
                        screen: 'fill_form_layout',
                        routes: [
                            { path: '/data', exact: true, screen: 'form' },
                            { path: '/', exact: false, screen: 'form_display_layout', routes: [
                                { path: '/output', exact: true, screen: 'form_output' },
                                { path: '/input', exact: true, screen: 'form_input' },
                                { path: '/documents', exact: true, screen: 'form_documents' },
                                { path: '/notes', exact: true, screen: 'form_notes' },
                                { path: '/settings', exact: true, screen: 'form_settings' },
                                { path: '/versions', exact: true, screen: 'form_versions' },
                                { path: '/', exact: true, screen: 'form_summary' }
                            ] }
                        ],
                    },
                ],
            },
            { exact: false, screen: 'not_found' },
        ],
    },
];

export default routes;
