export const GET_RUN = (gql: any) => gql`
    query ($id: ID!) {
        getRun(id: $id) {
            id
            version
            form
            ftemplate
            status
            definition
            selectedSolution
            jobOperationProgress
            jobOperationStatus
        }
    }
`;
export const SELECT_RUN_SOLUTION = (gql: any) => gql`
    mutation ($id: String!, $solution: String) {
        selectRunSolution(id: $id, solution: $solution) {
            id
        }
    }
`;