export const SAVE_FORM_DATA_CHANGES = (gql: any) => gql`
    mutation ($id: ID!, $changes: [SaveFormDataChangeInput]!) {
        saveFormDataChanges(id: $id, changes: $changes) {
            id
        }
    }
`;

export const SAVE_FORM_DATA_CHANGES_AND_RUN = (gql: any) => gql`
    mutation ($id: ID!, $changes: [SaveFormDataChangeInput]!) {
        saveFormDataChangesAndRun(id: $id, changes: $changes) {
            id
            version
        }
    }
`;