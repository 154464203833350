export const FIND_RUN_SOLUTIONS = (gql: any) => gql`
    query ($id: ID!) {
        findRunSolutions(id: $id) {
            cursor
            items {
                id
                key
                summary {
                    n
                    t
                    u
                    v
                    s
                    i
                    bi
                    f
                    b
                    l
                    il
                    bil
                    fl
                    bl
                }
                tags
                steps
                status
                computeDuration
                computeStartedAt
                computeEndedAt
                favorite
                name
            }
        }
    }
`;
export const GET_SOLUTION = (gql: any) => gql`
    query ($id: ID!) {
        getSolution(id: $id) {
            id
            status
            publicPdfUrl
            summary {
                n
                t
                u
                v
                s
                i
                bi
                f
                b
                l
                il
                bil
                fl
                bl
            }
            sfiles {
                items {
                    mainFile {
                        url
                        contentType
                        name
                    }
                    publicToken
                }
            }
            messages {
                level
                message
            }
            links {
                label
                url
            }
            violations {
                message
                level
                params
                variable
            }
            tags
            steps
            favorite
            name
        }
    }
`;
export const TOGGLE_FAVORITE_SOLUTION = (gql: any) => gql`
    mutation ($id: String!) {
        toggleFavoriteSolution(id: $id) {
            id
        }
    }
`;