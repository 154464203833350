import { RegisterAction } from "../molecules/actions/RegisterAction";
import { Text } from "@genstackio/react-admin-ui/lib/atoms/Text";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const styles = {
    backgroundImage: 'url(/static/media/wallpaper.150ca6b7.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};

export function OneFormTemplate({form}: OneFormTemplateProps) {
    const [hasAccount, setHasAccount] = useState<boolean>(true);
    const { t } = useTranslation();
    const onSwitch = useCallback(() => setHasAccount(!hasAccount), [hasAccount, setHasAccount]);

    return (
        <div className={'flex md:flex-row flex-col md:h-screen overflow-auto gap-2'}>
            <div className={'lg:w-1/3 md:w-1/2 w-full rounded-r-lg bg-gray-50 md:py-14 md:px-10 sm:p-10 p-2'}>
                {!!hasAccount && form}
                {!hasAccount && <RegisterAction />}
                <div className="flex items-center gap-2 pt-8">
                    <Text variant="body" text={t(hasAccount ? 'template_login_no_account_sentence' : 'template_login_has_account_sentence')}/>
                    <div className="underline text-co-primary hover:cursor-pointer hover:font-bold" onClick={onSwitch} children={t(hasAccount ? 'template_login_no_account_button' : 'template_login_has_account_button')}/>
                </div>
            </div>
            <div className={'flex-1'} style={styles} />
        </div>
    );
}

export interface OneFormTemplateProps {
    form?: any;
}

// noinspection JSUnusedGlobalSymbols
export default OneFormTemplate;