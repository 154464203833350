export const GET_VERSION = (gql: any) => gql`
    query ($id: ID!) {
        getVersion(id: $id) {
            id
            form
            data
            ftemplate
            status
            definition
            lastRun
            lastRunStatus
        }
    }
`;

export const FIND_FORM_VERSIONS = (gql: any) => gql`
query findFormVersions($id: ID!, $sort: String) {
    findFormVersions(id: $id, sort: $sort) {
        count
        cursor
        items {
            createdAt
            definition
            form
            ftemplate
            id
            status
            updatedAt
            lastRunStatus
        }
    }
}
`;