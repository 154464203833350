import { useMemo } from "react";

export function useDisplayableError(error: Error | undefined | { message?: string }) {
    return useMemo(() => {
        if (!error?.message) return undefined;

        switch (true) {
            case error?.message === "Response not successful: Received status code 400":
                return { message: 'error_message_malformed_request' };
            case error?.message?.includes('password: '):
                return { message: 'error_message_password_validator' };
            case error?.message?.includes('already exist'):
                return { message: 'error_message_already_exist' };
            case error?.message?.includes('Bad credentials'):
                return { message: 'error_message_bad_credentials' };
            case error?.message?.includes('#403'):
                return { message: 'error_message_user_suspended' };
            default:
                return error;
        }
    }, [error]);
}

// noinspection JSUnusedGlobalSymbols
export default useDisplayableError;