import {class_name} from "@genstackio/react-admin-ui/lib/types";
import clsx from "clsx";
import useDisplayableError from "../hooks/useDisplayableError";
import {useTranslation} from "react-i18next";
import {WithCenter} from "@genstackio/react-admin-ui/lib/withs";

export function ErrorPanel({center, error, className}: ErrorPanelProps) {
    const err = useDisplayableError(error);
    const {t} = useTranslation();

    if (!err?.message) return null;

    return (
        <p className={clsx('bg-red-500 text-white p-4 font-bold rounded', center && 'text-center', className)}>{'string' === typeof err.message ? t(err.message) : err.message}</p>
    );
}

export interface ErrorPanelProps extends WithCenter {
    error?: Error;
    className?: class_name;
}

export default ErrorPanel;