const de_DE = require('./translation-locales/de_DE.json');
const en_US = require('./translation-locales/en_US.json');
const es_ES = require('./translation-locales/es_ES.json');
const fr_FR = require('./translation-locales/fr_FR.json');
const it_IT = require('./translation-locales/it_IT.json');

const translations = {
    'de-DE': de_DE,
    'en-US': en_US,
    'es-ES': es_ES,
    'fr-FR': fr_FR,
    'it-IT': it_IT,
};

export default translations;
